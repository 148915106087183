<template>
    <div class="col-12">
        <error v-if="tokenData && transactionStatus === 'ERROR'" :amount="amount" :currencySymbol="tokenData.extra.currencySymbol" :paymentReference="paymentReference" :brandUrl="tokenData.extra.brandUrl"></error>
        <success v-else-if="tokenData && transactionStatus === 'SUCCESS'" type="deposit" :amount="amount" :currencySymbol="tokenData.extra.currencySymbol" :paymentReference="paymentReference" :brandUrl="tokenData.extra.brandUrl"></success>
        <warning v-else-if="tokenData && transactionStatus === 'PENDING'" :amount="amount" :currencySymbol="tokenData.extra.currencySymbol" :paymentReference="paymentReference" :brandUrl="tokenData.extra.brandUrl"></warning>
        <template v-else-if="transactionStatus || notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
            <p v-if="transactionStatus" class="alert alert-danger mt-5 text-center">{{transactionStatus}} {{$t('request-unsuccessful')}}</p>
        </template>
        <div v-else-if="tokenData" class="row justify-content-center mt-3">
            <div class="col-auto">
                <VCreditCard @change="creditCardInfoChanged" :trans="translations"></VCreditCard>
                <div class="amount">
                    <label class="amount-label" for="modalDepositAmount">{{$t('amount')}}</label>
                    <input type="number" class="amount-input" id="modalDepositAmount" v-model.trim="$v.amount.$model" :placeholder="$t('amount')" required>
                </div>
                <div class="col-12 p-0 validation-error" v-if="submitStatus === 'ERROR'">
                    <p v-if="!$v.name.required">{{$t('please-enter-your', { field: $t('name') })}}</p>
                    <p v-else-if="!$v.cardNumber.required">{{$t('please-enter-your', { field: $t('card-number') })}}</p>
                    <p v-else-if="!$v.cardNumber.validCardNumber">{{$t('invalid-field', {field: $t('card-number')} ) }}</p>
                    <p v-else-if="!$v.expiration.required">{{$t('please-enter-your', { field: $t('exp-date') })}}</p>
                    <p v-else-if="!$v.expiration.validExpiration">{{$t('invalid-field', {field: $t('exp-date')} ) }}</p>
                    <p v-else-if="!$v.security.required">{{$t('please-enter-your', { field: $t('cvv') })}}</p>
                    <p v-else-if="!$v.security.validCVV">{{$t('invalid-field', {field: $t('cvv')} ) }}</p>
                    <p v-else-if="!$v.amount.required">{{$t('please-enter-your', { field: $t('amount') })}}</p>
                    <p v-else-if="!$v.amount.minValue">{{$t('min-amount', { value: tokenData.extra.min/100}) }}</p>
                    <p v-else-if="!$v.amount.maxValue">{{$t('max-amount', { value: tokenData.extra.max/100}) }}</p>
                </div>
                <button class="btn btn-success col-12" @click="submit()" :class="{'disabled':submitStatus === 'PENDING'}">
                    <div v-if="submitStatus !== 'PENDING'"><strong>Submit</strong></div>
                    <div v-else class="d-flex align-items-center">
                        <strong>Loading...</strong>
                        <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import 'v-credit-card/dist/VCreditCard.css';
    import VCreditCard from 'v-credit-card';
    import cardValidator from 'card-validator';
    import {validationMixin} from 'vuelidate';
    import {minLength, required, numeric, minValue, maxValue} from 'vuelidate/lib/validators';
    import store from "../../../store/shared_state";
    import {creditCardDeposit} from "../../../services/backend.service";

    export default {
        name: 'CreditCardDeposit',
        data(){
            return{
                notEnoughData: null,
                name: null,
                cardNumber: null,
                expiration: null,
                security: null,
                amount: null,
                submitStatus: null,
                paymentReference: null,
                transactionStatus: null,
                translations :{
                    name: {
                        label: this.$t('name'),
                        placeholder: this.$t('name'),
                    },
                    card: {
                        label: this.$t('card-number'),
                        placeholder: this.$t('card-number')
                    },
                    expiration: {
                        label: this.$t('exp-date')
                    },
                    security: {
                        label: this.$t('cvv'),
                        placeholder: this.$t('cvv')
                    },
                }
            }
        },
        computed:{
            tokenData() { return store.state.tokenData },
        },
        components: {
            VCreditCard,
            Error: () => import('../../others/Error'),
            Warning: () => import('../../others/Warning'),
            Success: () => import('../../others/Success'),
        },
        mounted(){
            const tokenData = this.tokenData;

            console.log('-----tokenData from Store-----')
            console.log(tokenData)

            if(tokenData && tokenData.customerId && tokenData.extra.languageCode && tokenData.processorName && tokenData.ipAddress && tokenData.currencyCode){
                this.notEnoughData = false;
            } else {
                this.notEnoughData = true;
            }

            if(tokenData && tokenData.amount){
                this.amount = tokenData.amount;
            }
        },
        mixins: [validationMixin],
        validations() {
            return {
                name: {
                    required,
                    minLength: minLength(3),
                },
                cardNumber: {
                    required,
                    validCardNumber(cardNumber) {
                        const numberValidation = cardValidator.number(cardNumber);
                        return numberValidation.isValid === true;
                    }
                },
                expiration: {
                    required,
                    validExpiration(expiration) {
                        const expirationValidation = cardValidator.expirationDate(expiration);
                        return expirationValidation.isValid === true;
                    }
                },
                security: {
                    required,
                    validCVV(security) {
                        const cvvValidation = cardValidator.cvv(security);
                        return cvvValidation.isValid === true;
                    }
                },
                amount: {
                    required,
                    numeric,
                    minValue: minValue(this.tokenData.extra.min/100),
                    maxValue: maxValue(this.tokenData.extra.max/100),
                },
            }
        },
        methods:{
            creditCardInfoChanged(values) {
                for (const key in values) {
                    this[key] = values[key];
                }
            },
            submit(){
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR';
                } else {
                    this.submitStatus = 'PENDING';

                    let data = {
                        ccHolderName: this.name,
                        ccNumber: parseInt(this.cardNumber.replace(/\s/g, '')),
                        ccExpiryMonth: this.expiration.split('/')[0],
                        ccExpiryYear: this.expiration.split('/')[1],
                        ccCVV: parseInt(this.security),
                        language: this.tokenData.extra.languageCode,
                        processorType: "CARD",
                        currency: this.tokenData.currencyCode,
                        amount: this.amount*100,
                        processorName: this.tokenData.processorName,
                        customerId: this.tokenData.customerId,
                        ipAddress: this.tokenData.ipAddress,
                    };

                    console.log(data);

                    creditCardDeposit(data).then(res => {
                        console.log('-----creditCardDeposit response-----');
                        console.log(res);

                        if(res.data.success && res.data.data){
                            this.paymentReference = res.data.data.paymentReference;
                            this.submitStatus = 'OK';
                            const status = res.data.data.status;
                            this.transactionStatus = status === 'SUCCESS' || status === 'PENDING' || status === 'ERROR' ? res.data.data.status : 'ERROR';
                        } else {
                            this.submitStatus = 'ERROR';
                            this.transactionStatus = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                        }
                    }).catch( e => {
                        this.transactionStatus = this.$t('error-2');
                        console.log(`%c Error in creditCardDeposit.vue - method: creditCardDeposit - ${e}`, 'color:red');
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    .vue-credit-card .card-form-and-image {
        .credit-card-image, .credit-card-form {
            padding: 0;
            margin: 10px 0;
            .creditcard svg#cardback, .creditcard svg#cardfront {
                box-shadow: none;
            }
        }
        .credit-card-form .field {
            input {
                border: none;
                border-bottom: 2px solid #999;
                border-radius: 0px;
                padding: 5px;
                margin-top: 0px;
                outline: none;
            }
            label {
                padding: 0px;
                margin: 0px;
                font-size: 12px;
                text-align: left;
            }
            svg {
                top: calc(50% - 15px);
            }
        }
    }

    .amount-input{
        border: none;
        border-bottom: 2px solid #999;
        border-radius: 0px;
        padding: 5px;
        margin: 0 0 10px;
        outline: none;
        width:100%;
    }
    .amount-label {
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        text-align: left;
    }
</style>